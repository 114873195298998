// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-it-js": () => import("./../../../src/pages/contact.it.js" /* webpackChunkName: "component---src-pages-contact-it-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recent-en-js": () => import("./../../../src/pages/recent.en.js" /* webpackChunkName: "component---src-pages-recent-en-js" */),
  "component---src-pages-recent-it-js": () => import("./../../../src/pages/recent.it.js" /* webpackChunkName: "component---src-pages-recent-it-js" */),
  "component---src-pages-recent-js": () => import("./../../../src/pages/recent.js" /* webpackChunkName: "component---src-pages-recent-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-tours-en-js": () => import("./../../../src/pages/tours.en.js" /* webpackChunkName: "component---src-pages-tours-en-js" */),
  "component---src-pages-tours-it-js": () => import("./../../../src/pages/tours.it.js" /* webpackChunkName: "component---src-pages-tours-it-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-templates-tour-post-js": () => import("./../../../src/templates/tour-post.js" /* webpackChunkName: "component---src-templates-tour-post-js" */)
}

